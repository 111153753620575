/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

export default {
    transparent: "rgba(0,0,0,0)",
    translucent: "rgba(0,0,0,0.4)",
    translucentWhite: "rgba(255,255,255,0.6)",
    translucentWhiteXL: "rgba(255,255,255,0.9)",
    translucentWhiteNew: "rgba(255, 255, 255, 0.3)",
  
    // Example colors:
    greenV2: "#28D8A1",
    cartOrderTypeRgb: "rgba(9, 172, 99, 0.3)",
    golden: "#febe04",
    home_bg_v2: "#F1F0F5",
    grey1: "#bebebe",
    grey2: "#6A6A6A",
    grey3: "#848484",
    grey4: "#666",
  
    promoBorder: "rgba(0, 102, 48, 0.3)",
    socialshadow: "#aeaeae",
    appColor: "#00572d",
    appColorFade: "#a4e0c3",
    appColorFadeXl: "#d5f0e3",
    white: "#ffffff",
    colorAccent: "#f5c33c",
    blueish: "#4a4e59",
    text_color1: "#4F4F4D",
    text: "#212529",
    searchicon: "#979797",
    primary: "#0d2547",
    secondary: "#b2becf",
    secondarytheme: "#ee8833",
    ctaColor: "#3f9957",
    ctaColorFade: "#fcf0f1",
    home_gradient_end: "#403C36",
    inactive: "#47464b",
    success: "#28a745",
    iconmap: "#EA1F2600",
    Lavender: "#E6E6FA",
    directions: "#08B0FF",
    app_start_gradient: "#2d6943",
    app_end_gradient: "#2d6943",
  
    error: "#dc3545",
    error_red: "#d60000",
    map_text: "#08B0FF",
    app_bg: "#ffffff",
    new_header_color: "#959595",
    inactiveTabColor: "#eee",
    black: "#000000",
    gradientback: "#1d1b1b",
    // blackgrey: "#1A1C24",
    blackgrey: "#1d1b1b",
    blackText: "#0F2432",
    blackFade: "#333",
    pageBg: "#EDEFF4",
    inputTxtBg: "#EDEDED",
    border: "#f5f5f5",
    grey: "#F9F9F9",
    otpBorder: "#c2c3c8",
    ins_bg: "#F1F2F7",
    text_color: "#4F4F4D",
    text_color_grey: "#9EA2A1",
    order_type_active: "rgba(51,9,172,0.39)",
    separator: "#C4C8C7",
    cashback_bg: "#e4f7e4",
    whatsapp: "#075E54",
    facebook: "#3b5998",
    green_material: "#43a047",
    gold: "#FFD700",
    separator_color2: "#363535",
    green_new: "#138f4c",
    dark_green: "#043c07",
  
    green: "#00A953",
    red: "#ff4444",
    orange: "#ee8833",
    line_color: "#ee8833",
    yellow: "#ffbb33",
    lightGrey: "#F0F0F0",
    dark: "#1A1C24",
    darkBg: "#101D24",
    blue: "#08B0FF",
    silver: "#9d9ea2",
    silver1: "#C0C0C0",
    homeBg: "#F1F0F5",
    green2: "#459f45",
    green_icon: "#3DA66E",
    gray: "#808080",
    loyalty_bg_color: "#282828",
    loyalty_text_color: "#e3c188",
    explore_menu_feed_bg: "#ffffff",
    home_screen_bg: "#F1F0F5",
    placeholder_gray_text: "#d9d9d9",
  
    //new UI
    light_gray: "#F6F6F6",
    gray_text: "#848484",
    red1: "#DA251C",
    separator_color: "#E7E7E7",
    black_text: "#201C1D",
    light_green: "#E5F6EE",
    gray_border: "#EFEFEF",
    star_yellow: "#FDD42D",
    light_green2: "#B1E4C9",
    light_green3: "#EDFFEE",
    dark_red: "#9E1A14",
    light_red: "#FFA5A0",
    whatsapp_color: "#4cac54",
    skin_color: "#FCEBEB",
    screen_bg_color: "#F1F0F5",
    light_red2: "#FBF4F3",
    color_f7f7f7: "#F1F0F5",
    green3: "#5DE19B",
    green4: "#3DA66E",
    lightOrange: "#fff4eb",
    menuBg: "#F5BE19",
    paleYellow: "#FCE4B3",
    walletTextColor: "#000000",
    walletPointsBg: "rgba(252,236,235,0.6)",
    lightOragne: "#fca256",
    light_pink: "#fae6ed",
    light_pink2: "#f57fa7",
    gradientCtaColorLight: "#00572d",
    gradientCtaColorDark: "#00572d",
    new_color: "#12312"
  };
  