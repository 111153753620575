import React from 'react';
import "./gameModal.css";

function GameModal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay-game" onClick={onClose}>
      <div className="modal-game">
        <div className="modal-content-game">
          <span className="close" onClick={onClose}>&times;</span>
          {children}
        </div>

      </div>

    </div>
  );
}

export default GameModal;
