import { useEffect, useState } from "react"
import styled from "styled-components"

import { AppLogo, IplLogo } from "../../config/icons"
import { PageCenter } from "../../styles/Global"

const LogoAnimation = styled.div`
  svg {
    width: ${({ logoSize }) => logoSize};
    transition: width 1s;
  }
`

const SplashScreen = () => {
  const [logoSize, setLogoSize] = useState("80px")

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setLogoSize("240px")
      } else {
        setLogoSize("350px")
      }
    }

    // Set initial logo size
    handleResize()

    // Update logo size on window resize
    window.addEventListener("resize", handleResize)

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div style={{position:"absolute",left:0,right:0,top:0,bottom:0,maxHeight:"100vh",background:"red"}}>
    <PageCenter justifyCenter>
      {/* <div style={{position:"absolute",left:0,right:0,top:0,bottom:0}}> */}
      <LogoAnimation logoSize={logoSize}>
        {/* <AppLogo /> */}
        <IplLogo height={"100vh"} />
        {/* <div style={{ display: "flex", alignItems: "center"}}>
          <IplLogo />
          <p style={{ fontSize: "1.2rem", fontWeight: "normal", marginTop: "100px" }}>La Pino'z Pizza </p>  
        </div> */}
      </LogoAnimation>
      {/* </div> */}
    </PageCenter>
    </div>
  )
}

export default SplashScreen
