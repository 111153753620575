import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Loader = ({ showLoader }) => {
  return showLoader ? (
    <div
      style={{
        position: "absolute",
        left: "45vw",
        right: "45vw",
        top: "50vh",
        bottom: "50vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner animation="border" variant="warning" role="status" size="lg">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : null;
};

export default Loader;
