import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./ToastModal.css";

// 1. it's how we will use this component

// {showToast ? (
//   <ToastModal
//     showtoast={showToast.showtoast}
//     modalMsg={showToast?.modalMsg}
//     closeButton={showToast.closeButton}
//     closeIcon={showToast.closeIcon}
//     modalheading={showToast.modalheading}
//     onClose={() => setShowToast(null)}
//   />
// ) : null}

// 2. this is how we will set the state to show this component

// setShowToast({
//   modalMsg: "Data not available for today. Please try again later.",
//   modalheading: "Error",
//   showtoast: true,
//   closeButton: true,
//   closeIcon: true,
// });

function ToastModal({
  modalheading,
  modalMsg,
  onClose,
  showtoast,
  closeButton,
  closeIcon,
  faq,
  tnc,
}) {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };
  function DisplayHTML({ htmlContent }) {
    return (
      <div
        style={{ padding: "15px 0px" }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    );
  }

  return showtoast ? (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="toast-modal"
        style={{ margin: "0px 15px", overflowY: "scroll", maxHeight: "70vh" }}
      >
        {modalheading ? (
          <div
            style={{
              position: "sticky",
              top: 0,
              left: 0,
              right: 0,
              background: "white",
              zIndex: 9999,
              paddingTop: 20,
              paddingBottom: 5,
            }}
          >
            <Modal.Header closeButton={closeIcon} onClick={handleClose}>
              <Modal.Title>{modalheading}</Modal.Title>
            </Modal.Header>
          </div>
        ) : null}
        <div className="modal-content">
          {tnc && modalMsg ? (
            <div
              style={{
                padding: "5px 0px",
                overflowY: "scroll",
                maxHeight: "80vh",
              }}
            >
              <DisplayHTML htmlContent={modalMsg} />
              {/* <DisplayHTML htmlContent={modalMsg} /> */}
            </div>
          ) : modalMsg && faq ? (
            modalMsg.map((item, index) => (
              <div
                style={{ padding: index != modalMsg.length - 1 && "15px 0px" }}
              >
                <p style={{ fontSize: 18, fontWeight: "600" }}>
                  {item.question}
                </p>
                <p>{item.answer}</p>
              </div>
            ))
          ) : modalMsg ? (
            <Modal.Body style={{ padding: "15px 0px", fontSize: 18 }}>
              {modalMsg}
            </Modal.Body>
          ) : null}
          {onClose && closeButton ? (
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
}

export default ToastModal;
