import { createContext, useContext } from "react"
import { ScreenTypes } from "../types"

export const initialState = {
  currentScreen: ScreenTypes.SplashScreen,
  setCurrentScreen: () => {},
  quizTopic: "React",
  selectQuizTopic: () => {},
  questions: [],
  setQuestions: () => {},
  selectedQuiz: [],
  setSelectedQuiz: () => {},
  result: [],
  setResult: () => {},
  userData: [],
  setUserData: () => {},
  timer: 15,
  setTimer: () => {},
  endTime: 0,
  setActiveGamesApiResponse: () => {},
  activeGamesApiResponse: {},
  setIsGamePlayed: () => [],
  isGamePlayed: {},
  setSelectedGame: () => {},
  selectedGame: {},
  setEndTime: () => {},
  quizDetails: {
    totalQuestions: 0,
    totalScore: 0,
    totalTime: 0,
    selectedQuizTopic: "React"
  }
}

export const QuizContext = createContext(initialState)

export function useQuiz() {
  return useContext(QuizContext)
}
