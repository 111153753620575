import styled, { createGlobalStyle, css } from "styled-components"
import { device } from "../../../styles/BreakPoints"

export const ButtonStyle = styled.button.attrs(({ outline, bold, big }) => ({
  outline,
  bold,
  big
}))`
position: absolute;
top: 10px;
left: 10px;
  width: 50px;
  height: 50px;
  min-height: 50px;
  color: ${({ theme, outline }) =>
    outline ? theme.colors.outlineButtonText : theme.colors.buttonText};
  background: ${({ theme, outline }) =>
    outline ? theme.colors.cardBackground : theme.colors.buttonBackground};
  font-size: clamp(16px, 5vw, 24px);
  border: 1px solid
    ${({ theme, outline }) => (!outline ? "none" : theme.colors.themeColor)};
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.md} {
    width: ${({ big }) => (big ? "50px" : "50px")};
    min-height: 40px;
    tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }

  &:active {
    transform: scale(0.98);
    box-shadow: ${({ theme }) => theme.shadows.activeButton};
    transition: 0.2s all;
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.disabledButton};
    color: ${({ theme }) => theme.colors.darkGray};
    cursor: not-allowed;
    transform: unset;
    box-shadow: unset;
  }
`

export const BackIconDiv = styled.div`
  background: ${({ light, theme }) =>
    light ? `${theme.colors.brandColor}` : `${theme.colors.brandColor}`};
  display: flex;
  width: 50px;
  height: 50px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  position: fixed;
  top: 10px;
  left: 10px;
  border: 10px solid rgba(0, 0, 0, 0.05);
  // due to flex direction column
  ${({ justifyCenter }) =>
    justifyCenter &&
    css`
      justify-content: center;
    `}
`

export const IconLeft = styled.span`
  margin-right: 10px;
  display: flex;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.buttonText};
    }
  }
`

export const IconRight = styled.span`
  margin-left: 20px;
  display: flex;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.buttonText};
    }
  }
`
