import { generalKnowledge } from "./generalKnowledge"
import { javascript } from "./javascript"
import { python } from "./python"
import { react } from "./react"

export const quiz = {
  JavaScript: javascript,
  React: react,
  Python: python,
  "General Knowledge": generalKnowledge
}
