export let ScreenTypes

;(function(ScreenTypes) {
  ScreenTypes[(ScreenTypes["SplashScreen"] = 0)] = "SplashScreen"
  ScreenTypes[(ScreenTypes["QuizTopicsScreen"] = 1)] = "QuizTopicsScreen"
  ScreenTypes[(ScreenTypes["QuizDetailsScreen"] = 2)] = "QuizDetailsScreen"
  ScreenTypes[(ScreenTypes["QuestionScreen"] = 3)] = "QuestionScreen"
  ScreenTypes[(ScreenTypes["ResultScreen"] = 4)] = "ResultScreen"
  ScreenTypes[(ScreenTypes["MultipleGamesScreen"] = 5)] = "MultipleGamesScreen"
  ScreenTypes[(ScreenTypes["PastGamesScreen"] = 6)] = "PastGamesScreen"
})(ScreenTypes || (ScreenTypes = {}))
