export const formatDate = (dateString) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(dateString);

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  let hour = date.getHours();
  const minute = date.getMinutes();
  const period = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12;

  const formattedDate = `${day}${getOrdinalSuffix(
    day
  )} ${month} ${year} ${hour}.${minute.toString().padStart(2, "0")} ${period}`;
  return formattedDate;
};

const getOrdinalSuffix = (number) => {
  if (number === 1 || number === 21 || number === 31) {
    return "st";
  }
  if (number === 2 || number === 22) {
    return "nd";
  }
  if (number === 3 || number === 23) {
    return "rd";
  }
  return "th";
};
