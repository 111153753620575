import { memo, useEffect, useState } from "react";
import styled from "styled-components";

// import { AppLogo, CheckIcon, Next, TimerIcon } from "../../config/icons";
import { useQuiz } from "../../context/QuizContext";
import { useTimer } from "../../hooks";
import { device } from "../../styles/BreakPoints";
import { PageCenter } from "../../styles/Global";
import { ScreenTypes } from "../../types";
import QuesBg from "../../assets/images/ques_bg.png";
import GameLogo from "../../assets/images/game_logo.png";
import VsImg from "../../assets/images/vs_img.png";
import Colors from "../../styles/Colors";
import { makeApiCall } from "../../services/ApiServices";
import { API_CALL_TYPE, SAVE_GAME_API } from "../../services/Api";
import { BackIconDiv } from "../ui/BackButton/styled.js";
import BackArrowIcon from "../../assets/icons/arrow_back_white.png";
import Loader from "../Loader/Loader.js";
import Card from "react-bootstrap/Card";
import FAQIcon from "../../assets/icons/faq_ic.png";
import RightArrow from "../../assets/icons/right_arrow.png";
import ToastModal from "../ToastModal/ToastModal.js";

// import Button from "../ui/Button";
// import ModalWrapper from "../ui/ModalWrapper";
// import Question from "./Question";
// import QuizHeader from "./QuizHeader";
// import axios from "axios";
// import { useApiCall } from "../../services/ApiServices";
// import { API_CALL_TYPE, GET_GAMES_API } from "../../services/Api";

// const QuizContainer = styled.div`
//   width: 900px;
//   min-height: 500px;
//   background: ${({ theme }) => theme.colors.cardBackground};
//   border-radius: 4px;
//   padding: 30px 60px 80px 60px;
//   margin-bottom: 70px;
//   position: relative;
//   @media ${device.md} {
//     width: 100%;
//     padding: 15px 15px 80px 15px;
//   }
//   button {
//     span {
//       svg {
//         path {
//           fill: ${({ selectedAnswer, theme }) =>
//             selectedAnswer
//               ? `${theme.colors.buttonText}`
//               : `${theme.colors.darkGray}`};
//         }
//       }
//     }
//   }
// `;

// const LogoContainer = styled.div`
//   margin-top: 50px;
//   margin-bottom: 50px;
//   @media ${device.md} {
//     margin-top: 10px;
//     margin-bottom: 20px;
//     svg {
//       width: 185px;
//       height: 80px;
//     }
//   }
// `;

// const ButtonWrapper = styled.div`
//   position: absolute;
//   right: 60px;
//   bottom: 30px;
//   display: flex;
//   gap: 20px;
//   @media ${device.sm} {
//     justify-content: flex-end;
//     width: 90%;
//     right: 15px;
//   }
// `;

const QuizBgImg = styled.img`
  width: 100%;
  min-height: 100%;
  // height: 100%;
  position: absolute;
  // top: 70px;
  // transform: translate(-50%, 0%);
  // left: 50%;
`;

const GameLogoDiv = styled.div`
  width: 80%;
  position: absolute;
  top: 70px;
  transform: translate(-50%, 0%);
  left: 50%;
  text-align: center;
`;

const QuizQuesDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  position: relative;
  margin-top: 50px;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid white;
  padding: 15px 15px;
  font-size: 14px;
`;

const QuizQuesInnerDiv = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 0px 0px;
`;

const QuestionScreen = () => {
  const quesArr = [
    {
      quesId: 1,
      options: [
        {
          optionId: 2,
          title: "BANGALORE",
        },
        {
          optionId: 3,
          title: "CHENNAI",
        },
      ],
    },
  ];
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [showTimerModal, setShowTimerModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [currentSelectQuestion, setCurrentSelectQuestion] = useState(
    quesArr[0]
  );
  const [currentSelectOption, setCurrentSelectOption] = useState(null);
  const [showToast, setShowToast] = useState(null);

  const {
    questions,
    quizDetails,
    result,
    setResult,
    setCurrentScreen,
    timer,
    setTimer,
    setEndTime,
    selectedGame,
    selectedQuiz,
    userData,
  } = useQuiz();

  const currentQuestion = questions[activeQuestion];

  const { question, type, choices, code, image, correctAnswers } =
    currentQuestion;

  const onClickNext = () => {
    const isMatch =
      selectedAnswer.length === correctAnswers.length &&
      selectedAnswer.every((answer) => correctAnswers.includes(answer));

    // adding selected answer, and if answer matches key to result array with current question
    setResult([...result, { ...currentQuestion, selectedAnswer, isMatch }]);

    if (activeQuestion !== questions.length - 1) {
      setActiveQuestion((prev) => prev + 1);
    } else {
      // how long does it take to finish the quiz
      const timeTaken = quizDetails.totalTime - timer;
      setEndTime(timeTaken);
      setShowResultModal(true);
    }
    setSelectedAnswer([]);
  };

  useEffect(() => {
    const handleBackNavigation = (event) => {
      event.preventDefault();
      window.history.forward(); // Move forward in history to prevent going back
    };

    window.addEventListener("popstate", handleBackNavigation);

    return () => {
      window.removeEventListener("popstate", handleBackNavigation);
    };
  }, []);

  const handleAnswerSelection = (e) => {
    const { name, checked } = e.target;

    if (type === "MAQs") {
      if (selectedAnswer.includes(name)) {
        setSelectedAnswer((prevSelectedAnswer) =>
          prevSelectedAnswer.filter((element) => element !== name)
        );
      } else {
        setSelectedAnswer((prevSelectedAnswer) => [
          ...prevSelectedAnswer,
          name,
        ]);
      }
    }

    if (type === "MCQs" || type === "boolean") {
      if (checked) {
        setSelectedAnswer([name]);
      }
    }
  };

  const handleModal = () => {
    setCurrentScreen(ScreenTypes.ResultScreen);
    document.body.style.overflow = "auto";
  };

  // to prevent scrolling when modal is opened
  useEffect(() => {
    if (showTimerModal || showResultModal) {
      document.body.style.overflow = "hidden";
    }
  }, [showTimerModal, showResultModal]);

  useTimer(
    timer,
    quizDetails,
    setEndTime,
    setTimer,
    setShowTimerModal,
    showResultModal
  );

  const handleAnswerClick = (item) => {
    // console.log("handleAnswerClick called - ", item);
    setCurrentSelectOption(item);
  };

  async function getFinalSubmitApiCall() {
    setShowLoader(true);
    selectedGame.user_answer = currentSelectOption;
    let game_output = `{"question":"${
      selectedGame.question
    }","options":[${selectedGame.options
      .map((option) => `"${option}"`)
      .join(",")}],"user_answer":"${selectedGame.user_answer}"}`;

    let obj = JSON.stringify({
      business_id: userData.business_id,
      parent_business_id: userData.parent_business_id,
      game_id: selectedQuiz?.game_id,
      _id: selectedQuiz?._id,
      contactMappingId: userData.contactMappingId,
      game_output: game_output,
      mobile_number: userData.mobile_number,
      customer_name: userData.customer_name || "Guest",
    });
    makeApiCall(
      API_CALL_TYPE.POST_CALL,
      SAVE_GAME_API(),
      (response) => {
        setShowLoader(false);

        console.log("getFinalSubmitApiCall response - ", response);
        if (response && response.status == 1) {
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({ page: "home" })
          );
          setCurrentScreen(ScreenTypes.QuizTopicsScreen);
        } else {
          setShowToast({
            modalMsg: response?.msg,
            modalheading: "Please try again later.",
            showtoast: true,
            closeButton: false,
            closeIcon: true,
          });
        }
      },
      (err) => {
        setShowLoader(false);
        console.log("getFinalSubmitApiCall err - ", err);
      },
      obj,
      obj
    );
  }
  // console.log("selectedGame - ", selectedGame);

  return (
    <div style={{ position: "relative", height: "100vh",overflow:"hidden", overflowX:"hidden", overflowY:"auto",scrollBehavior:"smooth" }}>
      <PageCenter>
        <img src={QuesBg} style={{ minHeight: "130vh", width: "100%" }} />
        {/* <QuizBgImg src={QuesBg} /> */}
        <GameLogoDiv>
          <img src={GameLogo} />
          <QuizQuesDiv>
            <p
              style={{
                color: "black",
                textAlign: "center",
                fontWeight: "500",
                marginBottom: 10,
                fontSize: 20,
              }}
            >
              {selectedGame?.question}
            </p>
            {selectedGame &&
              selectedGame?.options &&
              selectedGame?.options?.length > 0 && (
                <QuizQuesInnerDiv style={{ flex: 1, position: "relative" }}>
                  {selectedGame.options.map((item, index) => (
                    <div key={index}>
                      <div>
                        <div
                          onClick={() => handleAnswerClick(item)}
                          style={{
                            flex: 1,

                            backgroundColor:
                              currentSelectOption == item
                                ? Colors.appColor
                                : "white",
                            borderRadius: 10,
                            padding: 55,
                            height: 150,
                          }}
                        >
                          <p
                            style={{
                              color:
                                currentSelectOption == item
                                  ? "white"
                                  : Colors.black,
                              textAlign: "center",
                              fontWeight: "600",
                              fontSize: 24,
                              // paddingBottom: 10,
                              // paddingTop: index !==0 ?40: 10,
                            }}
                          >
                            {item}
                          </p>
                        </div>
                        {index === 0 ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              position: "absolute",
                              justifyContent: "center",
                              bottom: 100,
                              left: 0,
                              right: 0,
                            }}
                          >
                            {!currentSelectOption ? (
                              <div
                                style={{
                                  width: "100%",
                                  height: 1,
                                  backgroundColor: "black",
                                  // marginLeft: 15,
                                }}
                              />
                            ) : null}
                            <img src={VsImg} style={{ width: 100 }} />
                            {!currentSelectOption ? (
                              <div
                                style={{
                                  width: "100%",
                                  height: 1,
                                  backgroundColor: "black",
                                  // marginRight: 15,
                                }}
                              />
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </QuizQuesInnerDiv>
              )}
          </QuizQuesDiv>
          {/* FAQs */}

          {selectedQuiz?.faq ? (
            <Card
              onClick={() => {
                // console.log("selectedQuiz",selectedQuiz);
                setShowToast({
                  modalMsg: selectedQuiz?.faq,
                  faq: true,
                  modalheading: "FAQ",
                  showtoast: true,
                  closeButton: false,
                  closeIcon: true,
                });
              }}
              body
              style={{
                background: "white",
                marginTop: 20,
                marginLeft: 10,
                marginRight: 10,
                borderRadius: 10,
                // padding: 10,
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img src={FAQIcon} style={{ width: "30px", height: "30px" }} />
                <p style={{ flex: 1, marginLeft: 10, fontWeight: "500" }}>
                  FAQs
                </p>
                <img
                  src={RightArrow}
                  style={{ width: "10px", height: "10px" }}
                />
              </div>
            </Card>
          ) : null}
          {/* Terms & Conditions */}
          {selectedQuiz?.tnc ? (
            <Card
              onClick={() => {
                setShowToast({
                  modalMsg: selectedQuiz?.tnc,
                  tnc: true,
                  modalheading: "Terms & Conditions",
                  showtoast: true,
                  closeButton: false,
                  closeIcon: true,
                });
              }}
              body
              style={{
                background: "white",
                marginTop: 10,
                marginLeft: 10,
                marginRight: 10,
                borderRadius: 10,
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img src={FAQIcon} style={{ width: "30px", height: "30px" }} />
                <p style={{ flex: 1, marginLeft: 10, fontWeight: "500" }}>
                  Terms & Conditions
                </p>
                <img
                  src={RightArrow}
                  style={{ width: "10px", height: "10px" }}
                />
              </div>
            </Card>
          ) : null}
          <div style={{ width: "100%", height: 50 }} />
        </GameLogoDiv>

        <div
          onClick={() => {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({ page: "home" })
            );
            setCurrentScreen(ScreenTypes.QuizTopicsScreen);
          }}
          style={{ position: "absolute", top: 10, left: 10 }}
        >
          <img src={BackArrowIcon} style={{ width: "30px", height: "30px" }} />
        </div>
      </PageCenter>
      <div
        onClick={() => {
          if (!currentSelectOption) {
            return
          }
          getFinalSubmitApiCall();
        }}
        style={{
          position: "fixed",
          left: 10,
          right: 10,
          bottom: 20,
          background: !currentSelectOption ? Colors.gray : Colors.appColor,
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 10,
        }}
      >
        <p
          style={{
            textAlign: "center",
            color: "white",
            fontFamily: "sans-serif",
          }}
        >
          Submit Prediction
        </p>
      </div>
      {showToast ? (
        <ToastModal
          showtoast={showToast.showtoast}
          modalMsg={showToast?.modalMsg}
          faq={showToast.faq}
          tnc={showToast.tnc}
          closeButton={showToast.closeButton}
          closeIcon={showToast.closeIcon}
          modalheading={showToast.modalheading}
          onClose={() => setShowToast(null)}
        />
      ) : null}
      <Loader showLoader={showLoader} />
    </div>
  );
};

export default memo(QuestionScreen);
